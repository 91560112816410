import { graphql } from 'gatsby';
import React from 'react';

import { Layout } from '../containers/layout';
import { KontaktTemplate } from '../pageTemplates/kontakt';

interface KontaktProps {
  data: GraphQLData;
}

const Kontakt = ({ data }: KontaktProps) => {
  return (
    <Layout footer={{ onlyNav: true }}>
      <KontaktTemplate data={data} />
    </Layout>
  );
};

export const query = graphql`
  query Kontakt {
    strapi {
      fact {
        openingHours
        phone
        email
        googleMapsLink
      }
      contact {
        subheader
        meta {
          title
          description
        }
        hero {
          alternativeText
          url
          file {
            ...Image
          }
        }
      }
    }
  }
`;

export default Kontakt;
